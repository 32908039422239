import { Layout } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';
import SiteFooter from '../SiteFooter';
import SiteHeader from '../SiteHeader';
// @ts-ignore
import { container } from './sitelayout.module.scss';

function SiteLayout({ children, location }) {
    return (
        <Layout style={{ backgroundColor: '#FFFFFF' }}>
            <Helmet>
                <meta charSet='utf-8' />
                <title>Aktiver HAN</title>
                <link rel='canonical' href='https://aktiverhan.no/' />
                <meta name='Description' content='Aktiver HAN - Aktiver HAN porten automatisk ved bruk av BankID' />
                <meta property='og:url' content='https://aktiverhan.no' />
                <meta property='og:type' content='website' />
                <meta property='og:title' content='Aktiver HAN' />
                <meta property='og:description' content='Aktiver HAN - Aktiver HAN porten automatisk ved bruk av BankID' />
                <link href="https://fonts.googleapis.com/css?family=Titillium+Web:200,400&display=swap" rel="stylesheet"></link>
            </Helmet>
            <SiteHeader />
            <main className={container}>
                {children}
            </main>
            <SiteFooter />
        </Layout>
    );
}

export default SiteLayout;
