import React from "react";

// @ts-ignore
import { container, logo } from './sitefooter.module.scss';
import { Link } from "@reach/router";


// @ts-ignore
import Logo from '../../../static/convivial-logo.inline.svg';

interface ISiteFooterState {
}

export default class SiteFooter extends React.Component<any, ISiteFooterState> {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    public render() {
        return (
            <div className={container}>
                Les om <Link to="/personvern">personvern</Link>
                <Logo className={logo} />
            </div>
        );
    }
}
