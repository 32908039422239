import React from 'react';

// @ts-ignore
import { container } from './siteheader.module.scss';

interface ISiteHeaderState {
}

export default class SiteHeader extends React.Component<any, ISiteHeaderState> {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    public render() {
        return (
            <div className={container}>
            </div>
        );
    }
}
